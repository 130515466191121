body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
.bg {
  background-image: url("./assets/header-img.png");
  width: 100%;
  background-position: center;
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
}
.map-responsive iframe {
  width: 950px !important;
  height: 300px;
}
.map-responsive {
  overflow: auto;
  width: 1150px;
}
@media only screen and (max-width: 600px) {
  .bg {
    height: 150px;
    width: 110%;
    background-size: contain;
  }
}

.map-responsive iframe {
  width: 1150px;
  position: relative;
  border: 0;
}
.nearest {
  font-weight: 600;
  color: green;
  font-size: 20px;
}
